// Libraries
import React from 'react';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import TitleBlock from 'marketing/components/TitleBlock';
import Counter from 'marketing/components/Counter';

// Styles
import { layoutContainer, counterContainer } from './styles.module.css';

export default (data) => {
  const {
    currentPrizeTotal,
    titleHeading,
    titleSubheading,
    pageLogo,
    pageHeading,
    pageBannerText,
    pageBackgroundImage,
    ticketInformation,
  } = data.pageContext.data;

  return (
    <LayoutContainer
      includeOrderTicketsCta
      includeWinnersLink={data.pageContext.winnersLink}
    >
      <div className="theme-background-primary">
        <div className={layoutContainer}>
          <TitleBlock
            mainHeading={titleHeading}
            subHeading={titleSubheading}
            onThemeBackground
          />
          <div
            className="u-full-width l-margin-bottom l-margin-top--40 u-center-canvas addon-page"
            style={pageBackgroundImage && { backgroundImage: `url(${pageBackgroundImage})` }}
          >
            <div className={layoutContainer}>
              {pageLogo
              && <img className="u-block u-margin-center l-margin-top" src={pageLogo} alt="" />}
              <div className="u-center-canvas l-padding-top">
                {pageHeading
                && <p className="h2 alt u-text-shadow">{pageHeading}</p>}
                <div className={counterContainer}>
                  <Counter total={currentPrizeTotal} />
                </div>
                {pageBannerText
                && <p className="h3 l-padding-bottom">{pageBannerText}</p>}
              </div>
            </div>
          </div>
          {ticketInformation
                && (
                <p className="h5 u-center-canvas l-padding-top u-no-margin">
                  {ticketInformation}
                </p>
                )}
        </div>
      </div>
    </LayoutContainer>
  );
};
